import { useContext } from 'react'
import { Button, Grid, IconButton, Modal, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { Box } from '@mui/system'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import { ClearModalStyles } from './ClearModalStyles'
import language from '../../../../language/language'

type ClearModalProps = {
  openClearModal: boolean
  setOpenClearModal: (openModal: boolean) => void
  setOpenEmptyModal: (value: boolean) => void
  handleCloseCustomModal: () => void
}

const ClearModal = ({
  openClearModal,
  setOpenClearModal,
  setOpenEmptyModal,
  handleCloseCustomModal
}: ClearModalProps) => {

  const { currentLanguage } = useContext(LanguageContext)

  return (
    <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
      <Modal
        open={openClearModal}
        onClose={() => setOpenClearModal(false)}
        sx={ClearModalStyles.clearModal}>
        <Grid
          container
          sx={[ClearModalStyles.container, ClearModalStyles.clearContainer]}>
          <Grid sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography sx={ClearModalStyles.clearHeader}>
            {(language as any)[currentLanguage].cancelRoute}
            </Typography>
            <IconButton
              onClick={() => {
                setOpenClearModal(false)
              }}>
              <ClearIcon fontSize="medium" />
            </IconButton>
          </Grid>
          <Box sx={ClearModalStyles.clearBody}>
          {(language as any)[currentLanguage].cancelModal}
          </Box>
          <Box sx={ClearModalStyles.saveButtonBox}>
            <Button
              variant={'secondary'}
              onClick={() => {
                setOpenEmptyModal(true)
                setOpenClearModal(false)
              }}
              sx={{ width: '50%', marginRight: '10px' }}>
              {(language as any)[currentLanguage].no}
            </Button>
            <Button
              onClick={() => {
                setOpenEmptyModal(false)
                setOpenClearModal(false)
                handleCloseCustomModal()
              }}
              variant={'primary'}
              sx={{ width: '50%' }}>
              {(language as any)[currentLanguage].yes}
            </Button>
          </Box>
        </Grid>
      </Modal>
    </Grid>
  )
}
export default ClearModal