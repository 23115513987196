import Colors from "../../../../assets/Colors";

export const ClearModalStyles = {
    clearModal: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        width: '100%'
    },
    container: {
        alignItems: 'center',
        backgroundColor: Colors.white,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '3px'
    },
    clearContainer: {
        width: '500px',
        padding: '24px'
    },
    saveButtonBox: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    clearHeader: {
        lineHeight: '24px',
        fontWeight: '700',
        fontSize: '18px',
        marginBottom: '20px',
        color: Colors.napaBlue
    },
    clearBody: {
        lineHeight: '20px',
        fontWeight: '400',
        fontSize: '14px',
        marginBottom: '20px',
        color: Colors.napaGrey4,
        width: '100%'
    }
}