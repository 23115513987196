import Colors from '../../../../assets/Colors'

export const emptyBuildRouteStyles = {
  buildRouteText: {
    color: Colors.napaBlue,
    fontWeight: '400',
    fontSize: '22px',
    textAlign: 'center',
    fontFamily: 'NAPA SANS',
    display: 'flex',
    padding: '0px 10px 10px'
  },
  invoiceDeliveryAddress: {
    color: Colors.napaBlue,
    fontWeight: '400',
    fontSize: '16px',
    fontFamily: 'NAPA SANS'
  },
  renderInvoiceBox: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '12px'
  },
  removeInvoiceType: {
    color: Colors.napaBlueLink,
    fontSize: '14px',
    paddingRight: '3px',
    marginTop: '2px',
    textTransform: 'none'
  },
  driversFormGrid: {
    display: 'flex',
  },
  driversForm: {
    width: {
      xs: '120px',
      sm: '160px',
      md: '370px'
    },
    top: '-8px'
  },
  driverType: {
    color: Colors.black,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'NAPA SANS',
    paddingBottom: '5px'
  },
  invoiceAddressLineType: {
    color: Colors.napaGrey,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    fontFamily: 'NAPA SANS'
  },
  returnStore: {
    background: Colors.napaGreyDisabled,
    padding: '6px',
    border: '1px solid rgb(193,193,193)',
    marginLeft: '-14px',
    marginRight: '-14px',
    marginTop: '-24px'
  },
  dialogActions: {
    padding: '10px 0px'
  },
  editButton: {
    color: Colors.napaBlueLink,
    textTransform: 'none'
  },
  buttonContainer: {
    marginTop: '10px',
    padding: '10px'
  },
  dottedContainer: {
    border: '1px solid transparent',
    borderImage: 'repeating-linear-gradient(45deg, #C1C1C1 0, #C1C1C1 8px, transparent 8px, transparent 10px) 10',
    margin: '10px 10px 30px',
    display: 'block'
  },
  backToStore: {
    marginTop: '10px',
    overflowY: 'visible'
  },
  paddingRL: {
    padding: '0 10px 20px'
  },
  marginT10: {
    marginTop: '10px'
  },
  driverText: {
    fontSize: '14px',
    paddingLeft: '10px'
  },
  downIcon: {
    color: Colors.white
  },
  invoiceText: {
    color: Colors.napaBlue,
    fontSize: '14px',
    fontWeight: '400'
  },
  addStop: {
    width: '100%',
    height: 'auto',
    padding: '3px 0',
    backgroundColor: Colors.napaBlueLink
  },
  marginT5: {
    marginTop: '5px'
  },
  marginR5: {
    marginRight: '5px'
  },
  RouteMapPreview: {
    top: '0px',
    display: 'flex',
    padding: '10px',
  },
}