import React from 'react'
import { Box } from '@mui/system'
import { Button } from '@mui/material'
import { SaveAlt } from '@mui/icons-material'
import ReactPDF, { Document, Page, Text, View, PDFDownloadLink, Image } from '@react-pdf/renderer'

import { DeliveryRoute, Invoice, Stop, Part } from '../../../../utilities/types/DeliveryRouteTypes'
import { pdfStyles } from './pdfGeneratorStyles'
import language from '../../../../language/language'

type PdfGeneratorProps = {
  completedRoutes: DeliveryRoute[]
  driverFullName: string,
  currentLanguage: string
}

const MyDocument = ({ completedRoutes, driverFullName, currentLanguage }: PdfGeneratorProps) => {
  const getRoutes = () => completedRoutes.map(
    (route: DeliveryRoute, routesIndex: number) => {
      const stops = route.stops.map(
        (stop: Stop, stopsIndex: number) => {
          if (stop.stopType === 'back_to_store') return [];
          if (stop.stopType === 'custom') {
            return (
              <View style={pdfStyles.stopStyle} key={stopsIndex}>
                <View>
                  <Text style={pdfStyles.storeNameStyle}>{stop?.customStopName}</Text>
                  <Text style={pdfStyles.storeAddressStyle}>
                    {stop?.addressLine1}
                  </Text>
                  <Text style={pdfStyles.deliveryStatusStyle}>
                    {stop?.delivered ? (language as any)[currentLanguage].delivered : (language as any)[currentLanguage].statusInprogress}
                  </Text>
                </View>
              </View>
            )
          }
          const invoices = stop.invoices?.map(
            (invoice: Invoice, invoicesIndex: number) => {
              const parts= invoice.parts.map(
                (part: Part, partsIndex: number)=> {
                  return (
                    <View
                      key={partsIndex}
                      style={
                        partsIndex > 0 && partsIndex < invoice.parts.length
                          ? { ...pdfStyles.partsRowStyle, ...pdfStyles.borderTopStyle }
                          : pdfStyles.partsRowStyle
                      }>
                      <Text style={pdfStyles.partsCellStyle}>{part.partNumber}</Text>
                      <Text style={pdfStyles.partsCellStyle}>{part.lineAbbreviation}</Text>
                      <Text style={pdfStyles.partsCellStyle}>{part.description}</Text>
                      <Text style={pdfStyles.partsCellStyle}>{part.requestedQuantity}</Text>
                    </View>
                  )
                }
              )
              return (
                <View key={invoicesIndex}>
                  <Text style={pdfStyles.storeNameStyle}>{invoice?.deliveryAddress?.name}</Text>
                  <Text style={pdfStyles.storeAddressStyle}>
                    {invoice?.deliveryAddress?.addressLine1}
                  </Text>
                  <Text style={pdfStyles.deliveryStatusStyle}>
                    {invoice?.deliveryStatus ? (language as any)[currentLanguage].delivered : (language as any)[currentLanguage].statusInprogress}
                  </Text>
                  <Text style={pdfStyles.invoiceNumberStyle}>
                    {(language as any)[currentLanguage].invoiceNumber + invoice.invoiceNumber}
                  </Text>
                  <View style={pdfStyles.partsRowStyle}>
                    <Text style={pdfStyles.partsCellStyle}>{(language as any)[currentLanguage].itemNumber}</Text>
                    <Text style={pdfStyles.partsCellStyle}>{(language as any)[currentLanguage].lineAbbreviation}</Text>
                    <Text style={pdfStyles.partsCellStyle}>{(language as any)[currentLanguage].description}</Text>
                    <Text style={pdfStyles.partsCellStyle}>{(language as any)[currentLanguage].quantity}</Text>
                  </View>
                  {parts}
                  <View style={{ ...pdfStyles.signatureContainer, ...pdfStyles.borderTopStyle }}>
                    <View style={pdfStyles.signatureCaptureName}>
                      <Text>{(language as any)[currentLanguage].print}*</Text>
                      <Text>{stop.signatureCaptureName}</Text>
                    </View>
                    <View style={pdfStyles.signatureCaptureImage}>
                      <Text>{(language as any)[currentLanguage].signature}</Text>
                      {stop.signatureCaptureImage && (
                        <Image
                          src={stop.signatureCaptureImage}
                          style={pdfStyles.signatureImageStyle}
                        />
                      )}
                    </View>
                  </View>
                  {stop.photoProofOfDelivery && (
                    <View>
                      <Text style={{fontSize: 14, paddingBottom: 5}}>{(language as any)[currentLanguage].deliveryPhoto}</Text>
                      <Image
                        src={() => {
                          return `data:image/jpeg;base64,${stop.photoProofOfDelivery}`
                        }}
                        style={{ width: '82px', height: '84px', border: '1px', borderRadius: '3px' }}
                      />
                    </View>
                  )}
                </View>
              )
            }
          )
          return (
            <View style={pdfStyles.stopStyle} key={stopsIndex}>
              {invoices}
            </View>
          )
        }
      )
      return (
        <View style={pdfStyles.section} key={routesIndex}>
          {stops}
        </View>
      )
    })


  return (
    <Document>
      <Page size="LETTER" style={pdfStyles.page}>
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.routeReview}>
            {(language as any)[currentLanguage].routeNumber}{completedRoutes && completedRoutes[0].routeId}
          </Text>
          <Text style={pdfStyles.subtext}>{(language as any)[currentLanguage].driver}: {driverFullName}</Text>
          <Text style={pdfStyles.subtext}>{(language as any)[currentLanguage].totalStops} {completedRoutes[0].stops?.length - 1}</Text>
        </View>
        {getRoutes()}
      </Page>
    </Document>
  )
}

const PdfGenerator = (props: PdfGeneratorProps) => {
  const {
    completedRoutes: [{ routeId }],
    currentLanguage
  } = props
  const fileName: string = `Route#${routeId}`

  const DownloadButton = (
    <Box sx={{ textAlign: 'center' }}>
      <Button variant="text" startIcon={<SaveAlt />}>
        {(language as any)[currentLanguage].pdf}
      </Button>
    </Box>
  )
  const pdfDownloadLink: ReactPDF.PDFDownloadLink = new PDFDownloadLink({
    document: <MyDocument {...props} />,
    children: DownloadButton,
    fileName
  })

  return <>{pdfDownloadLink}</>
}

export default PdfGenerator
